import React from "react";
import facebook from "../../Assets/facebook.svg";
import instagram from "../../Assets/instagram.svg";
import googlePlay from "../../Assets/googlePlay.svg";
import appStore from "../../Assets/appStore.svg";
import "./Footer.css";
function Footer() {
	return (
		<div className="footer app-padding">
			<div className="footer-container">
				<div className="my-2">
					<div className="title">Stay in touch with us</div>
					<img src={facebook} alt="facebook" className="mx-2" />
					<img src={instagram} alt="instagram" className="mx-2" />
				</div>
				<div className="my-2">
					<div className="title">Download the app</div>
					<img src={googlePlay} alt="googlePlay" className="mx-2" />
					<img src={appStore} alt="appStore" className="mx-2" />
				</div>
			</div>
			<div className="text-center">All rights reserved &copy; AWSS 2023</div>
		</div>
	);
}

export default Footer;
