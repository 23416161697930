import React from "react";
import Header from "../Components/Header/Header";
import ContactForm from "../Components/ContactForm/ContactForm";
import Footer from "../Components/Footer/Footer";
import { ToastContainer } from "react-toastify";

function Contact() {
	return (
		<div>
			{" "}
			<Header />
			<ContactForm />
			<Footer />
			<ToastContainer />
		</div>
	);
}

export default Contact;
