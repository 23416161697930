import React from "react";
import logo from "../../Assets/logo.png";
import world from "../../Assets/world.svg";
import "./Header.css";
function Header() {
	return (
		<div className="header app-padding">
			<img src={logo} alt="logo" />
			<img src={world} alt="world" />
		</div>
	);
}

export default Header;
