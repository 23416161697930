import { Route, Routes } from "react-router-dom";
import Contact from "./Pages/Contact";
import Staticwebsite from "./Pages/StaticWebsite/Staticwebsite";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";

function RoutesFile() {
  return (
    <Routes>
      <Route exact path={"/"} element={<Staticwebsite />} />
      <Route path={"/offers"} element={<Contact />} />
      <Route path={"/privacyPolicy"} element={<PrivacyPolicy />} />
    </Routes>
  );
}
export default RoutesFile;
