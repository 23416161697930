import RoutesFile from "./Routes";
import "./App.css";

function App() {
	return (
		<div className="App section">
			<RoutesFile />
		</div>
	);
}

export default App;
