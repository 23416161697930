import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo.png";
import hands from "../../Assets/hand.svg";
import search from "../../Assets/search-icon.svg";
import aboutUs1 from "../../Assets/about-us-1.svg";
import aboutUs2 from "../../Assets/about-us-2.svg";
import aboutUs3 from "../../Assets/about-us-3.svg";
import aboutUs4 from "../../Assets/about-us-4.svg";
import aboutUs5 from "../../Assets/about-us-5.svg";
import mission from "../../Assets/mission.svg";
import vision from "../../Assets/vision.svg";
import ourValues from "../../Assets/our-values.svg";
import facebook from "../../Assets/facebook.svg";
import instagram from "../../Assets/instagram.svg";
import googlePlay from "../../Assets/googlePlay.svg";
import appStore from "../../Assets/app-store.png";
import Mark from "mark.js";
import "./StaticWebsite.css";
function Staticwebsite() {
  const [searchTerm, setSearchterm] = useState("");
  const markInstance = new Mark(document.querySelector("#search-node"));
  console.log(markInstance);
  const handleSearch = (event) => {
    console.log("ss");
    setSearchterm(event.target.value);
    markInstance.unmark({
      done: () => {
        markInstance.mark(event.target.value);
      },
    });
  };
  return (
    <div className='static' id='search-node'>
      {/* <!-- Start navbar section --> */}
      <nav className='nav-bar p-4'>
        <div className='d-flex justify-content-between'>
          <img src={logo} alt='logo' />
          <div className='main-title color-white'>Contact Us</div>
        </div>
        <div className='d-flex justify-content-center my-4'>
          <div className='main-title-bold color-white'>Welcome!</div>
          <img src={hands} className='mx-2' alt='hand' />
        </div>
        <div className='d-flex justify-content-center search-box'>
          <input
            type='text'
            placeholder='How could we help you?'
            className='search-input'
            onChange={handleSearch}
            value={searchTerm}
          />
          <img src={search} alt='search-icon' className='cursor-pointer' />
        </div>
      </nav>
      {/* <!-- End navbar section --> */}
      {/* <!-- Start about us Section --> */}
      <section className='my-4 p-4'>
        <div className='d-flex'>
          <img src={aboutUs1} alt='about-us-1' className='about-us-image' />
          <img src={aboutUs2} alt='about-us-2' className='about-us-image' />
          <img src={aboutUs3} alt='about-us-3' className='about-us-image' />
          <img src={aboutUs4} alt='about-us-4' className='about-us-image' />
          <img src={aboutUs5} alt='about-us-5' className='about-us-image' />
        </div>
        <div className='about-us p-4'>
          <div className='main-title-bold title'>About us</div>
          <div className='text'>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book. It has survived not only five
            centuries,is simply dummy text of the printing and typesetting
            industry.
          </div>
        </div>
      </section>
      {/* <!-- End about us Section --> */}
      {/* <!-- Start missionVision section --> */}
      <section className='d-flex missionVision p-4 flex-wrap justify-content-between'>
        <div className='d-flex flex-column align-items-center item'>
          <div className='main-title'>Mission</div>
          <img src={mission} alt='mission' />
          <div className='text'>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy Lorem Ipsum has been
            the industry's standard dummy
          </div>
        </div>
        <div className='d-flex flex-column align-items-center  item'>
          <div className='main-title'>Vision</div>
          <img src={vision} alt='vision' />
          <div className='text'>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy Lorem Ipsum has been
            the industry's standard dummy
          </div>
        </div>
        <div className='d-flex flex-column align-items-center  item'>
          <div className='main-title'>Our values</div>
          <img src={ourValues} alt='mission' />
          <div className='text'>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy Lorem Ipsum has been
            the industry's standard dummy
          </div>
        </div>
      </section>
      {/* <!-- End missionVision section --> */}
      {/* <!-- Start footer section --> */}
      <footer className='d-flex justify-content-between flex-wrap mt-4'>
        <div className='mx-2'>
          <div className='title mb-4'>Social media</div>
          <div>
            <Link to={"/"}>
              <img src={facebook} alt='facebook' />
            </Link>
            <Link to={"/"}>
              <img src={instagram} alt='instagram' className='mx-2' />
            </Link>
          </div>
        </div>
        <div className='mx-2'>
          <div className='title mb-4'>Company</div>
          <ul className='list-unstyled'>
            <li className='text'>
              <Link to={"/"}>Careers</Link>
            </li>
            <li className='text'>
              <Link to={"/"}>Blogs</Link>
            </li>
            <li className='text'>
              <Link to={"/"}>Terms and conditions</Link>
            </li>
            <li className='text'>
              <Link to={"/privacyPolicy"}>Privacy policy</Link>
            </li>
          </ul>
        </div>
        <div className='mx-2'>
          <div className='title mb-4'>Download the App</div>
          <div className='mb-2'>
            <a href='https://play.google.com/store/apps/details?id=com.nationtech.themarket'>
              <img src={googlePlay} alt='google-play' />
            </a>
          </div>
          <div>
            <Link to={"/"}>
              <img src={appStore} alt='app-store' />
            </Link>
          </div>
        </div>
      </footer>
      {/* <!-- End footer section --> */}
    </div>
  );
}

export default Staticwebsite;
