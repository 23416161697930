import React, { useState } from "react";
import medal from "../../Assets/medal.svg";
import user from "../../Assets/user.svg";
import { Button, Form, Input } from "antd";
import { handleSubmitContactUsForm } from "../../Network/Network";
import { toastSuccess } from "../../Helpers/toasters";
import "./ContactForm.css";

function ContactForm() {
	const [isLoading, updateIsLoading] = useState(false);

	const onFinish = (values) => {
		updateIsLoading(true);
		let data = {
			...values,
			offerLink: window.location.href,
		};
		handleSubmitContactUsForm(
			data,
			(success) => {
				if (success.success) {
					toastSuccess(success.message);
				}
				updateIsLoading(false);
			},
			(fail) => {
				updateIsLoading(false);
			}
		);
	};
	const onFinishFailed = (errorInfo) => {};
	const preventCharNumbers = (e) => {
		if (
			!(
				e.code.includes("Digit") ||
				e.code === "Backspace" ||
				e.code.includes("Arrow") ||
				e.code.includes("Numpad")
			) ||
			e.shiftKey
		) {
			e.preventDefault();
		}
	};
	return (
		<div className="contact app-padding">
			<div className="title">
				<img src={medal} alt="medal" />
				<span>Welcome to AWSS offers!</span>
				<img src={medal} alt="medal" />
			</div>
			<div className="subtitle">
				To get this offer, please enter your data and we will contact you very
				soon:
			</div>
			<Form
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				layout="vertical"
				style={{ width: "300px" }}>
				<Form.Item
					label="Name"
					name="name"
					rules={[
						{
							required: true,
							message: "Please, Enter your name!",
						},
					]}>
					<Input
						prefix={<img src={user} alt="user" className="user-img" />}
						placeholder="Enter your Name"
					/>
				</Form.Item>
				<Form.Item
					label="Mobile Number"
					name="mobile"
					rules={[
						{
							required: true,
							message: "Please, Enter a valid mobile number",
							validator: (rule, value) => {
								const mobile = value;
								if (
									!(
										mobile.startsWith("010") ||
										mobile.startsWith("011") ||
										mobile.startsWith("012") ||
										mobile.startsWith("015")
									) ||
									mobile.toString().length < 11
								) {
									return Promise.reject(
										new Error("Please, Enter a valid mobile number")
									);
								} else {
									return Promise.resolve();
								}
							},
						},
					]}>
					<Input
						placeholder="Enter your mobile"
						onKeyDown={preventCharNumbers}
					/>
				</Form.Item>
				<Button
					loading={isLoading}
					className="primary-button my-4"
					htmlType="primary">
					Submit
				</Button>
			</Form>
		</div>
	);
}

export default ContactForm;
